import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import Layout from '../components/Layout';

import TopHeader from '../views/shared/TopHeaderV2';

import ActivityCard from '../views/shared/ActivityCard';

import HeaderSeo from '../components/HeaderSeo';

const Container = styled.div`
  font-family: 'Open Sans', sans-serif;
  padding: 26px 36px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  @media (max-width: 894px) {
    padding: 17px 34px;
  }
`;
const H1 = styled.h1`
  &.bigFont {
    font-weight: 600;
    font-size: 42px;
    line-height: 34px;
  }
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 44px;
`;
const SubTitle = styled.h4`
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const PrivacyPolicy = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const privacyConst = {
    title: <div>GoGet Privacy policy</div>,
    subTitle: (
      <SubTitle>
        <div>Privacy Notice</div>
        “Personal Data” refers to information about an identifiable individual,
        but excludes the designation, business address and/or telephone number
        of an individual who is an employee of an organisation.
        <br /> <br />
        The Personal Data that GoGet.My retains are sourced from information
        provided by you in past dealings with GoGet.My and/or may be provided by
        you in the future through your use of products and/or services provided
        by GoGet.My.
      </SubTitle>
    )
  };

  return (
    <>
      <HeaderSeo
        title="GoGet | Privacy Policy"
        pathname={props.location.pathname}
      />
      <Layout>
        <TopHeader
          isPrivacy
          title={privacyConst.title}
          description={privacyConst.subTitle}
          h4="Privacy"
          backgroundMobileHeight="unset"
        />
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            position: 'relative',
            top: '-5rem'
          }}
        >
          <Container>
            Where you provide GoGet.My with Personal Data relating to third
            parties, you confirm that you are authorized to or have obtained the
            prior consent of such third parties before providing GoGet.My with
            the same.
            <br />
            <br />
            GoGet.My collects, processes and retains Personal Data for its
            legitimate functions, including but not limited to: effecting
            matching of posts between Participants; managing and administering
            your account(s); dealings with suppliers and business associates;
            advertising products and services provided by GoGet.My; managing the
            security and safety of GoGet.My premises and facilities; conducting
            customer satisfaction surveys; and/or for such other purposes as may
            be required by law.
            <br />
            <br />
            When a user enables GoGet.My to track location, we will trace
            precise and approximate location data from the user’s mobile device.
            GoGet.My will collect data when a GoGetter or Job Poster runs the
            app in the foreground (app open and on-screen) or background (app
            open but not on screen) of their mobile device. One example of
            location information usage would be; GoGetter app uses location in
            the background to track GoGetters’ location when doing jobs and to
            inform them via push notifications of new jobs posted nearby them.
            GoGetters and Posters may use GoGet.My apps without enabling
            location data from their mobile device. However, this may affect
            some functionality in our apps.
            <br />
            <br />
            Where the Personal Data is indicated as being mandatory, GoGet.My
            may in certain instances be faced with delays or be unable to
            provide you with the contracted services which you require should
            you fail to provide GoGet.My the required Personal Data.
            <br />
            <br />
            GoGet.My also outsources certain of its internal functions, whether
            based in Malaysia or abroad, including but not limited to invoicing,
            information technology services, to third parties.
            <br />
            <br />
            GoGet.My wishes to assure you that other than in the instances
            stated herein and for any other legitimate purposes, GoGet.My will
            not share, sell, rent, trade and/or disclose your Personal Data to
            any unauthorised third party in contravention of applicable laws
            unless your consent has been obtained.
            <br />
            <br />
            The Personal Data Protection Act 2010 grants you certain rights to
            seek access to and/or to correct Personal Data retained by GoGet.My.
            This right may be exercised at any time, however, please be advised
            that in certain limited circumstances, your rights may be restricted
            pursuant to the Personal Data Protection Act 2010.
            <br />
            <br />
            Should there be any change in or request for deletion of your
            Personal Data, it shall be your responsibility to advise and update
            GoGet.My in writing.
            <br />
            <br />
            Should you have any questions regarding your Personal Data, please
            contact our Data Protection Officer at any of the addresses provided
            below:
            <br />
            <br />
            Eternal Meteor Sdn Bhd
            <br />
            1 Level 16 No,
            <br />
            Wisma Uoa Damansara II,
            <br />
            Penthouse 16, 6, Changkat Semantan, Bukit Damansara,
            <br />
            50490 Kuala Lumpur
            <br />
            <a href="mailto: contact@goget.my">contact@goget.my</a>
            <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px'
          }}
        >
          <Container>
            <H1 className="bigFont">Privacy Policy</H1>
            BY REGISTERING FOR OR USING THE GOGET.MY PLATFORM AND WEBSITE, YOU
            AGREE TO THE FOLLOWING PRIVACY POLICY.
            <H1>Personal Data</H1>
            <>
              Personal data is specific information about personal or factual
              characteristics relating to a certain natural person or a natural
              person who can be specified. This includes information such as
              your real name, address, telephone number and date of birth.
              Information which cannot be directly linked to your real identity
              - such as favorite websites or number of users of a site - is not
              considered personal data.
            </>
            <br />
            <br />
            <br />
            <H1>Utilization and Passing On of Personal Data</H1>
            <>
              We use the personal data which you have made available to us
              exclusively for technical administration of the web pages and to
              fulfill your wishes and requests. In turn, it helps us improve the
              services we offer to you, and to make our website's content and
              services easier to use and more appropriate to you.
              <br /> <br />
              Only when you have previously granted your approval or - if so
              stipulated by legal regulations - have not raised an objection do
              we also use this data for product-related surveys and marketing
              purposes.
              <br /> <br />
              GoGet.My does not share, sell, transfer or otherwise disseminate
              your personal data to third parties and will not do so in future,
              unless required by law, or unless you have given express consent
              to do so. For instance, it may be necessary to pass on your phone
              number to our GoGetters when you are successfully matched on
              GoGet.My for ease of contact.
            </>
            <br />
            <br />
            <br />
            <H1>Information form GoGet.my</H1>
            <>
              GoGet.My would like to contact you in order to provide you with
              the latest information about updates to our website or our offers,
              news, products and services. If you contact or register with
              GoGet.My on our web pages, we sometimes request that you indicate
              whether or not you would like to receive direct advertising
              material from us. If you are already a customer of GoGet.My, you
              will naturally continue to receive necessary information about
              important changes.
            </>
            <br />
            <br />
            <br />
            <H1>Use of Web Tracking</H1>
            <>
              We use tracking software to determine how many users visit our
              website and how often. We do not use this software to collect
              individual personal data or individual IP addresses. The data are
              used solely in anonymous and summarized form for statistical
              purposes and for developing the website.
            </>
            <br />
            <br />
            <br />
            <H1>Security</H1>
            <>
              GoGet.My takes all of the necessary technical and organizational
              security measures to protect your personal data from being lost or
              misused. For instance, your data is saved in a secure operating
              environment which is not accessible to the public. In certain
              cases, your personal data is encrypted by Secure Socket Layer
              technology (SSL) during transmission. This means that an approved
              encryption procedure is used for communication between your
              computer and the GoGet.My servers if your browser supports SSL.
              <br />
              <br />
              Should you wish to contact GoGet.My by e-mail, we would like to
              point out that the confidentiality of the information sent cannot
              be guaranteed. The contents of e-mail messages can be read by
              third parties. We therefore recommend you send us confidential
              information only by post.
            </>
            <br />
            <br />
            <br />
            <H1>Right to Information</H1>
            <>
              Upon written request, we will inform you what personal data (e.g.
              name, address) we have saved on you. You have the right to request
              for data deletion of your personal data (e.g. name, phone number)
              upon written request.
            </>
            <br />
            <br />
            <br />
          </Container>
        </ActivityCard>
        <br /> <br />
        <br />
        <br />
        <br />
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
